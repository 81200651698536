import "./App.scss";
import HomePage from "./pages/Home/HomePage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import SchedulePickupPage from "./pages/SchedulePickupPage/SchedulePickupPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="router-wrap">
        <BrowserRouter>
          <Navbar />
          <div className="pages-wrap">
            <Routes>
              <Route index element={<HomePage />} />
              <Route path="schedule-pickup" element={<SchedulePickupPage />} />
            </Routes>
          </div>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
