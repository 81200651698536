import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./HomePage.scss";

export default function HomePage() {
  const navigate = useNavigate();

  useEffect(() => {
    let scrollTo = document.getElementById(window.location.hash.substring(1));
    if (scrollTo) {
      console.log("scrolling into view");
      scrollTo.scrollIntoView();
    }
  }, [window.location.hash]);

  return (
    <div className="home-page page">
      <section id="page-top">
        <div id="main-slide" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="item active">
              <img
                className="img-responsive"
                width="100%"
                src="asset/images/slide-1.jpg"
                alt="slider"
              />
              <div className="slider-content">
                <div className="col-md-12 text-center">
                  <p
                    className="animated3"
                    style={{
                      textTransform: "capitalize",
                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                      fontSize: 21,
                      fontWeight: 800,
                    }}
                  >
                    Ultimate choice for busy people everywhere
                  </p>
                  {/* <a href="#portfolio-modal" data-toggle="modal" className="link-1 page-scroll btn btn-primary animated1">Who are we?</a> */}
                  <NavLink
                    to="schedule-pickup"
                    className="link-1 page-scroll btn btn-primary animated1"
                  >
                    Place Order Now
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="ratecard" className="feature-section-1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title text-center">
                <h3>Our Pricing</h3>
                <p>Structured for Your Needs</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="pricing-tabs fg-light-red">
                <ul className="nav nav-tabs col-md-12">
                  <li className="nav-item active">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#wash-dry-fold"
                    >
                      Wash | Dry | Fold
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#iron">
                      &amp; Iron
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane fade active in" id="wash-dry-fold">
                    <div className="pricing-card text-center">
                      <h3 className="text-center pricing-head">
                        Wash | Dry | Fold
                      </h3>
                      <ul>
                        <li>
                          Up to 4 Kgs <strong>&#8377;220/-</strong>
                        </li>
                        <li>
                          Up to 5 Kgs <strong>&#8377;250/-</strong>
                        </li>
                        <li>
                          Up to 6 Kgs <strong>&#8377;280/-</strong>
                        </li>
                        <li>
                          Above 6 Kgs <strong>&#8377;55 per Kg</strong>
                        </li>
                        <li>
                          Single Spread Blankets <strong>&#8377;150/-</strong>
                        </li>
                        <li>
                          Double Spread Blankets <strong>&#8377;250/-</strong>
                        </li>
                        <li>
                          Over Size Blankets <strong>&#8377;250/-</strong>
                        </li>
                        <li>
                          Vaccum Steam Press{" "}
                          <strong>&#8377;10 per Piece</strong>
                        </li>
                        <li>
                          Fabric Conditioner <strong>&#8377;30 per Load</strong>
                        </li>
                        <li className="nav-item">
                          Pick up & Delivery <strong>&#8377;60/-</strong>
                        </li>
                        <NavLink
                          to="schedule-pickup"
                          className="link-1 btn btn-primary animated1 pricing-order-btn"
                        >
                          Place Order
                        </NavLink>
                        <span className="conditions-apply">
                          Conditions Apply
                        </span>
                      </ul>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="iron">
                    <div className="pricing-card text-center">
                      <h3 className="text-center pricing-head">
                        Wash | Dry | Fold | Iron
                      </h3>
                      <ul>
                        <li>
                          Up to 4 Kgs <strong>&#8377;350/-</strong>
                        </li>
                        <li>
                          Above 4 Kgs <strong>&#8377;90 per Kg</strong>
                        </li>
                        <li className="nav-item">
                          Pick up & Delivery <strong>&#8377;60/-</strong>
                        </li>
                        <NavLink
                          to="schedule-pickup"
                          className="link-1 page-scroll btn btn-primary animated1 pricing-order-btn"
                        >
                          Place Order
                        </NavLink>
                        <span className="conditions-apply">
                          Conditions Apply
                        </span>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="service" className="feature-section-1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title text-center">
                <h3>Our Services</h3>
                <p>
                  Happy Clothes, Happy us, Experience the new level of cleaning.
                  Keep your busy schedule for your growth, family & friends. We
                  will take care of your laundry.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="feature">
                <i
                  className="fa fa-simplybuilt"
                  onClick={() => navigate("/schedule-pickup")}
                ></i>
                <div className="feature-content">
                  <h4>Wash</h4>
                  <p>
                    Our process is to go hard on stains. We transform your
                    dirty, solied clothes into clean, fresh & soft.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="feature">
                <i
                  className="fa fa-shopping-basket"
                  onClick={() => navigate("/schedule-pickup")}
                ></i>
                <div className="feature-content">
                  <h4>Dry</h4>
                  <p>
                    With help of latest machinery we do dry your clothes on
                    right temprature
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="feature">
                <i
                  className="fa fa-gift"
                  onClick={() => navigate("/schedule-pickup")}
                ></i>
                <div className="feature-content">
                  <h4>Iron & Fold</h4>
                  <p>
                    With extra care your clothes are Ironed & Folded as fresh
                    new clothes
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="feature">
                <i
                  className="fa fa-truck"
                  onClick={() => navigate("/schedule-pickup")}
                ></i>
                <div className="feature-content">
                  <h4>Pick up & Delivery</h4>
                  <p>Call or Email us, we will take care of your laundry</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="locate-us" className="portfolio-section-1">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="section-title text-center">
                <h3>Locate Us</h3>
                <p>We are near to you!</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 col-sm-4 col-xs-6">
              <div className="feature">
                <a href="https://goo.gl/maps/vfnqjH9uyD9rurBc8" target="_blank">
                  {" "}
                  <i className="fa fa-map-marker"></i>
                </a>
                <div className="feature-content">
                  <h4>Bommanahalli</h4>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-4 col-xs-6">
              <div className="feature">
                <a href="https://goo.gl/maps/Ti69Kf1WdzJfLh1X8" target="_blank">
                  {" "}
                  <i className="fa fa-map-marker"></i>
                </a>
                <div className="feature-content">
                  <h4>Electronic City</h4>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-4 col-xs-6">
              <div className="feature">
                <a href="https://goo.gl/maps/gNvBybGX9JczxnAg9" target="_blank">
                  {" "}
                  <i className="fa fa-map-marker"></i>
                </a>
                <div className="feature-content">
                  <h4>Maruti Nagar</h4>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-4 col-xs-6">
              <div className="feature">
                <a href="https://goo.gl/maps/1FxiadkzWwAQ4E3L9" target="_blank">
                  {" "}
                  <i className="fa fa-map-marker"></i>
                </a>
                <div className="feature-content">
                  <h4>Madiwala</h4>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-4 col-xs-6">
              <div className="feature">
                <a href="https://goo.gl/maps/H8dRnYc48KnZrCK46" target="_blank">
                  {" "}
                  <i className="fa fa-map-marker"></i>
                </a>
                <div className="feature-content">
                  <h4>Hongasandra</h4>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-4 col-xs-6">
              <div className="feature">
                <a
                  href="https://maps.app.goo.gl/tobg4jXKBSX4e46r7"
                  target="_blank"
                >
                  {" "}
                  <i className="fa fa-map-marker"></i>
                </a>
                <div className="feature-content">
                  <h4>Shanthipura phase 2</h4>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-4 col-xs-6">
              <div className="feature">
                <a
                  href="https://maps.app.goo.gl/n2VQWKUe1J7t6fEv8"
                  target="_blank"
                >
                  {" "}
                  <i className="fa fa-map-marker"></i>
                </a>
                <div className="feature-content">
                  <h4>Mangamanpalaya HSR LAYOUT</h4>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-4 col-xs-6">
              <div className="feature">
                <a
                  href="https://maps.app.goo.gl/Zz6APz6PEtRdVsBj8"
                  target="_blank"
                >
                  {" "}
                  <i className="fa fa-map-marker"></i>
                </a>
                <div className="feature-content">
                  <h4>Konappana agrahara</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="section-title text-center">
                <h3>Contact Us</h3>
                <p>Do you have any query about our Service!</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="col-md-6">
                <div className="thumbnail bg-light-red">
                  <h3 className="text-center">Contact info</h3>
                  <p></p>
                  <ul>
                    <li>
                      <i
                        className="fa fa-envelope bg-logo-rev"
                        area-hidden="true"
                      ></i>{" "}
                      <strong />{" "}
                      <h4
                        style={{
                          display: "inline",
                          textTransform: "lowercase",
                        }}
                      >
                        <a
                          href="mailto:contactus@speedylaundry.in"
                          style={{ color: "#fff" }}
                        >
                          {" "}
                          contactus@speedylaundry.in
                        </a>
                      </h4>{" "}
                    </li>
                    <li>
                      <i
                        className="fa fa-envelope bg-logo-rev"
                        area-hidden="true"
                      ></i>{" "}
                      <strong />{" "}
                      <h4
                        style={{
                          display: "inline",
                          textTransform: "lowercase",
                        }}
                      >
                        <a
                          href="mailto:support@speedylaundry.in"
                          style={{ color: "#fff" }}
                        >
                          {" "}
                          support@speedylaundry.in
                        </a>
                      </h4>
                    </li>
                    <li>
                      <i
                        className="fa fa-phone bg-logo-rev"
                        area-hidden="true"
                      ></i>{" "}
                      <a className="white" href="tel:+917411534499">
                        +91-74115 34499
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="thumbnail bg-light-red">
                  <h3 className="text-center">Working Hours</h3>
                  <p></p>
                  <ul>
                    <li>
                      <i className="fa fa-clock-o bg-logo-rev"></i>{" "}
                      <strong> 8 AM to 9.30 PM</strong>
                    </li>
                    <li>We are open all days of week.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>

        <div
          className="section-modal modal fade"
          id="portfolio-modal"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-content">
            <div className="close-modal" data-dismiss="modal">
              <div className="lr">
                <div className="rl"></div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="section-title text-center">
                  <h3>About Us</h3>
                  <p></p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <p>
                    Our Team dedicated in providing laundry service including
                    wash, dry, press & fold. Ensuring customer satisification
                  </p>
                  <p>
                    With latest machinery, we take special care of your clothes
                    to turn into fresh, clean laundry.
                  </p>
                  <p>
                    For your all speedy laundry needs trust us, Give us call
                  </p>
                  <div>
                    {" "}
                    <i
                      className="fa fa-phone bg-logo"
                      area-hidden="true"
                    ></i>{" "}
                    <h3 style={{ display: "inline" }}>
                      <a href="tel:+917411534499">+91-74115 34499</a>
                    </h3>
                  </div>
                  <div>
                    {" "}
                    <i
                      className="fa fa-envelope bg-logo"
                      area-hidden="true"
                    ></i>{" "}
                    <h3
                      style={{ display: "inline", textTransform: "lowercase" }}
                    >
                      <a href="mailto:contactus@speedylaundry.in">
                        {" "}
                        contactus@speedylaundry.in
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="section-modal modal fade"
          id="portfolio-modal-book-laundry"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-content">
            <div className="close-modal" data-dismiss="modal">
              <div className="lr">
                <div className="rl"></div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="section-title text-center">
                  <h3>Service Request</h3>
                  <p>
                    Leave us your address and valid phone no we will schedule
                    pickup.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <form name="sentMessage" id="contactForm">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Your Name *"
                            id="name"
                            required
                            data-validation-required-message="Please enter your name."
                          />
                          <p className="help-block text-danger"></p>
                        </div>
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Your Email *"
                            id="email"
                            required
                            data-validation-required-message="Please enter your email address."
                          />
                          <p className="help-block text-danger"></p>
                        </div>
                        <div className="form-group">
                          <input
                            type="tel"
                            className="form-control"
                            placeholder="Your Phone *"
                            id="phone"
                            required
                            data-validation-required-message="Please enter your phone number."
                          />
                          <p className="help-block text-danger"></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            placeholder="Your Message *"
                            id="message"
                            required
                            data-validation-required-message="Please enter a message."
                          ></textarea>
                          <p className="help-block text-danger"></p>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                      <div className="col-lg-12 text-center">
                        <div id="success"></div>
                        <button type="submit" className="btn btn-primary">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="style-1">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <span className="copyright">
                  Copyright &copy;{" "}
                  <a href="http://speedylaundry.in">SpeedyLaundry</a> 2023
                </span>
              </div>
              <div className="col-md-6 col-xs-12">
                <span className="copyright">
                  All prices listed are subject to change. For the most
                  up-to-date pricing, please contact us
                </span>
              </div>
            </div>
          </div>
        </footer>
      </section>
    </div>
  );
}
