import { useRef } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.scss";

export default function Navbar() {
  const navbarRef = useRef<HTMLDivElement>(null);

  function hideNavBar() {
    if (navbarRef) {
      if (navbarRef.current?.classList.contains("in")) {
        navbarRef.current?.classList.remove("in");
      }
    }
  }

  return (
    <div className="navbar  navbar-default navbar-fixed-top navbar-shrink">
      <div className="container">
        <div className="navbar-header page-scroll">
          <button
            type="button"
            className="navbar-toggle"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <NavLink to="/" className="navbar-brand page-scroll">
            Sree Speedy Laundry
          </NavLink>
        </div>
        <div
          className="collapse navbar-collapse"
          ref={navbarRef}
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li className="hidden">
              <a href="/">f</a>
            </li>
            <li>
              <a className="page-scroll" href="/#service" onClick={hideNavBar}>
                Service
              </a>
            </li>
            <li>
              <a className="page-scroll" href="/#ratecard" onClick={hideNavBar}>
                Our Pricing
              </a>
            </li>
            <li>
              <a
                className="page-scroll"
                href="/#locate-us"
                onClick={hideNavBar}
              >
                Locate us
              </a>
            </li>
            <li>
              <a className="page-scroll" href="/#contact" onClick={hideNavBar}>
                Contact
              </a>
            </li>
            <li>
              <NavLink to="schedule-pickup" onClick={hideNavBar}>
                Order Now
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
