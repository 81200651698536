import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./SchedulePickupPage.scss";
import { PulseLoader } from "react-spinners";

export default function SchedulePickupPage() {
  const navigate = useNavigate();

  const [submittingRequest, setSubmittingRequest] = useState(false);

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [address, setAddress] = useState("");

  const onPhoneNumberChange = (e: any) => {
    const re = /^(0|(\+|\+9|\+91))?\d{0,10}$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      setPhoneNumber(e.target.value);
    }
  };

  const checkValidPhoneNumber = (number: string) => {
    let validPhoneNumberRegs = [/^\+91\d{10}$/, /^0\d{10}$/, /^\d{10}$/];

    for (let i = 0; i < validPhoneNumberRegs.length; i++) {
      if (validPhoneNumberRegs[i].test(number)) {
        return true;
      }
    }

    toast.error(
      "Not a valid Phone Number. use xxxxxxxxxx or +91xxxxxxxxxx or 0xxxxxxxxxx"
    );
    return false;
  };

  function submitPickupRequest() {
    if (
      !name ||
      !phoneNumber ||
      !address ||
      !checkValidPhoneNumber(phoneNumber)
    ) {
      return;
    }

    intimateNewOrder(name, phoneNumber, address);
  }

  function intimateNewOrder(
    name: string,
    phoneNumber: string,
    address: string
  ) {
    setSubmittingRequest(true);

    let data = new FormData();
    data.append("name", name);
    data.append("phone", phoneNumber);
    data.append("address", address);

    //fetch("https://dev.speedylaundry.in/mail/intimate_order.php", {
    fetch("/mail/intimate_order.php", {
      method: "POST",
      body: data,
    }).then((res: Response) => {
      if (res.status === 200) {
        navigate("/");
        toast.success("Pickup Requested Successfully");
      } else if (res.status === 503) {
        setSubmittingRequest(false);
      }
    });
  }

  return (
    <div className="schedule-pickup-page page">
      <div className="row">
        <div className="section-title text-center">
          <h3>Schedule Pickup</h3>
          <p>
            Leave us your name, address and valid phone number we will schedule
            pickup.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-center">
          <form
            name="sentMessage"
            id="contactForm"
            onSubmit={(e: any) => e.preventDefault()}
          >
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Name:</label>
                  <input
                    value={name}
                    onChange={(e: any) => setName(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Your Name *"
                    id="name"
                    required
                    data-validation-required-message="Please enter your name."
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-group">
                  <label>Phone Number:</label>
                  <input
                    value={phoneNumber}
                    onChange={onPhoneNumberChange}
                    type="tel"
                    className="form-control"
                    placeholder="Your Phone *"
                    id="phone"
                    required
                    data-validation-required-message="Please enter your phone number."
                  />
                  <p className="help-block text-danger"></p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Address:</label>
                  <textarea
                    value={address}
                    onChange={(e: any) => setAddress(e.target.value)}
                    className="form-control"
                    placeholder="Pickup Address *"
                    id="address"
                    required
                    data-validation-required-message="Please enter pickup Address."
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="col-lg-12 text-center">
                {submittingRequest ? (
                  <PulseLoader color="#FF432E" />
                ) : (
                  <button
                    type="submit"
                    onClick={submitPickupRequest}
                    className="btn btn-primary"
                  >
                    SUBMIT
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
